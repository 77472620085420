/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import { TermsAndConditions, PrivacyPolicy } from '..';
import { useTranslation } from '../../shared/hooks';
import * as translations from './intl';
import CookiesPolicy from '../CookiesPolicy';
import CookiesPolicyGlobalUS from '../CookiePolicyGlobalUS';
import CookiePolicyTripUK from '../CookiePolicyTripUK';
import CookiePolicyGeneric from '../CookiePolicyGeneric';
import CookiePolicyInsurFor from '../CookiePolicyInsurFor';
import CookiePolicyUSChubb from '../CookiePolicyUSChubb';
import CookiePolicyUSMetlife from '../CookiePolicyUSMetlife';
import CookiePolicyUSTI from '../CookiePolicyUSTI';
import CookiePolicyWwiis from '../CookiePolicyWwiis';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import { Button } from '..';
import './styles.css';

const styles = makeStyles((theme) => ({
  container: {
    flexShrink: 0,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    background: theme.palette.primary.footerbackgroundcolor,
    color: theme.palette.text.main,
  },
  containerConfirm: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    background: theme.palette.primary.footerbackgroundcolor,
    color: theme.palette.text.main,
    position: 'absolute',
    bottom: '0',
  },
  footerLink: {
    textDecoration: 'none',
    color: 'white',
    padding: '0.5em',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      cursor: 'pointer',
    },
  },
  footerCopyright: {
    textDecoration: 'none',
    color: 'white',
    padding: '10px 6em 0 0',
  },
  footerCopyrightMobile: {
    textDecoration: 'none',
    color: 'white',
    padding: '0.5em',
  },
  pipe: {
    textDecoration: 'none',
    color: 'white',
    padding: '0em',
    margin: '0',
  },
  split: {
    borderBottom: '1px solid white',
    padding: '15px 0 15px 6em',
  },
  splitMobile: {
    borderBottom: '1px solid white',
  },
  p1: {
    padding: 10,
    [theme.breakpoints.down('md')]: {
      padding: '10px 0px',
      margin: '0px 10px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
    },
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
}));

export default function Footer() {
  const classes = styles();
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });

  const {
    TERMS_AND_CONDITIONS,
    PRIVACY,
    COOKIES,
    COOKIE_SETTINGS,
    PRIVACY_POLICY,
  } = intl;

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
  const [showCookiesPolicyGlobalUS, setShowCookiesPolicyGlobalUS] =
    useState(false);
  const [showCookiesPolicyTripUK, setShowCookiesPolicyTripUK] = useState(false);
  const [showCookiesPolicyGeneric, setShowCookiesPolicyGeneric] =
    useState(false);
  const [showCookiesPolicyInsurFor, setShowCookiesPolicyInsurFor] =
    useState(false);
  const [showCookiesPolicyUSChubb, setShowCookiesPolicyUSChubb] =
    useState(false);
  const [showCookiesPolicyUSMetlife, setShowCookiesPolicyUSMetlife] =
    useState(false);
  const [showCookiesPolicyUSTI, setShowCookiesPolicyUSTI] = useState(false);
  const [showCookiesPolicyWwiis, setShowCookiesPolicyWwiis] = useState(false);

  const handleToggleTerms = () => {
    setShowTerms((prevShowTerms) => !prevShowTerms);
    if (!showTerms) {
      window.dataLayer.push({
        zone: 'footer',
        element_clicked: 'faq',
        event: 'navigation',
      });
    }
  };

  const handleTogglePrivacy = () => {
    setShowPrivacy((prevShowPrivacy) => !prevShowPrivacy);
    if (!showPrivacy) {
      window.dataLayer.push({
        zone: 'footer',
        element_clicked: 'data privacy policy',
        event: 'navigation',
      });
    }
  };

  const handleToggleCookiesPolicy = () => {
    const genericDomain = process.env.REACT_APP_DOMAIN_GENERIC;
    const tripUKDomain = process.env.REACT_APP_DOMAIN_TRIPUK;
    const insurforDomain = process.env.REACT_APP_DOMAIN_INSUREFOR;
    const globalUSDomain = process.env.REACT_APP_DOMAIN_US;
    const chubbDomain = process.env.REACT_APP_DOMAIN_US_CHUBB;
    const metlifeDomain = process.env.REACT_APP_DOMAIN_US_METLIFE;
    const ustiDomain = process.env.REACT_APP_DOMAIN_USTI;
    const wwiisDomain = process.env.REACT_APP_DOMAIN_WWIIS;
    const currentDomain = window.location.hostname;

    let showDialog = false;

    if (currentDomain === genericDomain) {
      showDialog = !showCookiesPolicyGeneric;
    } else if (currentDomain === tripUKDomain) {
      showDialog = !showCookiesPolicyTripUK;
    } else if (currentDomain === insurforDomain) {
      showDialog = !showCookiesPolicyInsurFor;
    } else if (currentDomain === globalUSDomain) {
      showDialog = !showCookiesPolicyGlobalUS;
    } else if (currentDomain === chubbDomain) {
      showDialog = !showCookiesPolicyUSChubb;
    } else if (currentDomain === metlifeDomain) {
      showDialog = !showCookiesPolicyUSMetlife;
    } else if (currentDomain === ustiDomain) {
      showDialog = !showCookiesPolicyUSTI;
    } else if (currentDomain === wwiisDomain) {
      showDialog = !showCookiesPolicyWwiis;
    } else {
      showDialog = !showCookiesPolicy;
    }

    if (showDialog) {
      window.dataLayer.push({
        zone: 'footer',
        element_clicked: `cookies policy`,
        event: 'navigation',
      });
    }

    if (currentDomain === genericDomain) {
      setShowCookiesPolicyGeneric(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === tripUKDomain) {
      setShowCookiesPolicyTripUK(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === insurforDomain) {
      setShowCookiesPolicyInsurFor(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === globalUSDomain) {
      setShowCookiesPolicyGlobalUS(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === chubbDomain) {
      setShowCookiesPolicyUSChubb(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === metlifeDomain) {
      setShowCookiesPolicyUSMetlife(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else if (currentDomain === ustiDomain) {
      setShowCookiesPolicyUSTI((prevShowCookiePolicy) => !prevShowCookiePolicy);
    } else if (currentDomain === wwiisDomain) {
      setShowCookiesPolicyWwiis(
        (prevShowCookiePolicy) => !prevShowCookiePolicy
      );
    } else {
      setShowCookiesPolicy((prevShowCookiePolicy) => !prevShowCookiePolicy);
    }
  };

  const { userData } = useContext(GeneralContext);
  const classContainer = userData?.email_verified
    ? classes.container
    : classes.containerConfirm;

  return (
    <>
      <TermsAndConditions
        showTerms={showTerms}
        handleShowTerms={handleToggleTerms}
      />
      <PrivacyPolicy
        showPrivacy={showPrivacy}
        handleShowPrivacy={handleTogglePrivacy}
      />
      <CookiesPolicy
        showCookiesPolicy={showCookiesPolicy}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyTripUK
        showCookiesPolicyTripUK={showCookiesPolicyTripUK}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiesPolicyGlobalUS
        showCookiesPolicyGlobalUS={showCookiesPolicyGlobalUS}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyGeneric
        showCookiesPolicyGeneric={showCookiesPolicyGeneric}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyInsurFor
        showCookiesPolicyInsurFor={showCookiesPolicyInsurFor}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyUSChubb
        showCookiesPolicyUSChubb={showCookiesPolicyUSChubb}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyUSMetlife
        showCookiesPolicyUSMetlife={showCookiesPolicyUSMetlife}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyUSTI
        showCookiesPolicyUSTI={showCookiesPolicyUSTI}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <CookiePolicyWwiis
        showCookiesPolicyWwiis={showCookiesPolicyWwiis}
        handleShowCookiesPolicy={handleToggleCookiesPolicy}
      />
      <Grid className={classContainer} data-testid="footer">
        <Grid container>
          <Hidden only={['sm', 'xs']}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={classes.split}
            >
              <Grid item className={classes.footerLink}>
                {TermsConditions(
                  classes,
                  TERMS_AND_CONDITIONS,
                  handleToggleTerms
                )}
              </Grid>
              <Grid item className={classes.footerLink}>
                |
              </Grid>
              <Grid item>{Privacy(classes, PRIVACY, handleTogglePrivacy)}</Grid>
              <Grid item className={classes.footerLink}>
                |
              </Grid>
              <Grid item>
                {Cookies(classes, COOKIES, handleToggleCookiesPolicy)}
              </Grid>
              <Grid item className={classes.footerLink}>
                |
              </Grid>
              <Grid item>
                <Button
                  id="ot-sdk-btn"
                  class="ot-sdk-show-settings"
                  name="cookie"
                  text={COOKIE_SETTINGS}
                ></Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center">
              <p className={`${classes.footerCopyright} ${classes.sourceSans}`}>
                {PRIVACY_POLICY}
              </p>
            </Grid>
          </Hidden>
          <Hidden only={['xl', 'lg', 'md']}>
            <Grid container direction="column" className={classes.splitMobile}>
              <Grid item className={`${classes.p1}`}>
                {TermsConditions(
                  classes,
                  TERMS_AND_CONDITIONS,
                  handleToggleTerms
                )}
              </Grid>
              <Grid item className={`${classes.p1}`}>
                {Privacy(classes, PRIVACY, handleTogglePrivacy)}
              </Grid>
              <Grid item className={`${classes.p1}`}>
                {Cookies(classes, COOKIES, handleToggleCookiesPolicy)}
              </Grid>
              <Grid item>
                <Button
                  id="ot-sdk-btn"
                  class="ot-sdk-show-settings"
                  name="cookie"
                  text={COOKIE_SETTINGS}
                ></Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <p
                className={`${classes.footerCopyrightMobile} ${classes.sourceSans}`}
              >
                {PRIVACY_POLICY}
              </p>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
}

function TermsConditions(classes, TERMS_AND_CONDITIONS, handleShowTerms) {
  return (
    <a
      className={`${classes.footerLink} ${classes.sourceSans}`}
      onClick={handleShowTerms}
    >
      {TERMS_AND_CONDITIONS}
    </a>
  );
}

function Privacy(classes, PRIVACY, handleShowPrivacy) {
  return (
    <a
      className={`${classes.footerLink} ${classes.sourceSans}`}
      onClick={handleShowPrivacy}
    >
      {PRIVACY}
    </a>
  );
}

function Cookies(classes, COOKIES, handleShowCookiesPolicy) {
  return (
    <a
      className={`${classes.footerLink} ${classes.sourceSans}`}
      onClick={handleShowCookiesPolicy}
    >
      {COOKIES}
    </a>
  );
}
