/* eslint-disable no-undef */
import { getCookie } from './shared/helpers';

const idiom = getCookie('idiom');
export let language = '';

if (idiom == 'en_US') {
  language = 'EN';
} else if (idiom == 'es_ES') {
  language = 'ES';
} else if (idiom == 'fr_FR') {
  language = 'FR';
} else if (idiom == 'pt_BR') {
  language = 'PT';
} else if (idiom == 'de-DE') {
  language = 'DE';
} else if (idiom == 'it_IT') {
  language = 'IT';
}

// Get the URL parameters
const urlParams = new URLSearchParams(window.location.search);

// Get UTM parameters
const utmSource = urlParams.get('utm_source');
const utmCampaign = urlParams.get('utm_campaign');
const utmMedium = urlParams.get('utm_medium');
const utmContent = urlParams.get('utm_content');

export let partner_id = '';
export let partner_name = '';
export let country_code = '';

// Check UTM parameters for each domain
if (window.location.hostname == process.env.REACT_APP_DOMAIN_GENERIC) {
  if (utmSource && utmCampaign && utmMedium && utmContent) {
    partner_id = utmCampaign;
    partner_name = utmMedium;
    country_code = utmContent;
  } else if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'undefined EU';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'undefined EU';
    partner_name = 'undefined EU';
    country_code = 'undefined EU';
  }
} else if (window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'TRIP.COM1';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'TRIP.COM1';
    partner_name = 'Trip.com';
    country_code = 'GB';
  }
} else if (window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'Insurfor_EXT';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'Insurfor_EXT';
    partner_name = 'Insurfor';
    country_code = 'EU';
  }
} else if (window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'CHUBB_EXT';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'CHUBB_EXT';
    partner_name = 'Chubb';
    country_code = 'US';
  }
} else if (
  window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE
) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'METLIFE1';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'METLIFE1';
    partner_name = 'Metlife';
    country_code = 'US';
  }
} else if (window.location.hostname == process.env.REACT_APP_DOMAIN_USTI) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'USTI1';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'USTI1';
    partner_name = 'Usti';
    country_code = 'EU';
  }
} else if (window.location.hostname === process.env.REACT_APP_DOMAIN_US) {
  if (utmSource && utmCampaign && utmMedium && utmContent) {
    partner_id = utmCampaign;
    partner_name = utmMedium;
    country_code = utmContent;
  } else if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'undefined US';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'undefined US';
    partner_name = 'undefined US';
    country_code = 'undefined US';
  }
} else if (window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS) {
  if (
    utmSource == 'sms' ||
    utmSource == 'IVR' ||
    utmSource == 'web' ||
    utmSource == 'certificate' ||
    utmSource == 'email'
  ) {
    partner_id = 'WWIIS';
    if (utmContent) {
      partner_name = utmContent.split('-')[0];
      country_code = utmContent.split('-')[1];
    }
  } else {
    partner_id = 'WWIIS';
    partner_name = 'WWIIS';
    country_code = 'GB';
  }
}

export let instance_env = '';
if (window.location.hostname.includes('test')) {
  instance_env = 'uat';
} else if (window.location.hostname.includes('localhost')) {
  instance_env = 'local';
} else if (window.location.hostname.includes('uk-trip-costumer')) {
  instance_env = 'local';
} else if (window.location.href.includes(':3000')) {
  instance_env = 'local';
} else if (window.location.href.includes('azurewebsites')) {
  instance_env = 'uat';
} else if (window.location.hostname.includes('ppa')) {
  instance_env = 'uat';
} else {
  instance_env = 'prod';
}
