/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import Home from '../pages/Home/Home';
import LetsStart from '../pages/LetsStart';
import PatientSelection from '../pages/PatientSelection';
import Request from '../pages/Request';
import { pitang, basic } from '../styles/themes';
import { Header, Footer, LoginCallback } from '../components';
import { CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useTranslation, useHashFragment } from '../shared/hooks';
import {
  HOME,
  HOME_WITH_THEME,
  LETS_START,
  LOGIN,
  LOGOUT,
  LOGIN_CALLBACK,
  REQUEST,
  PATIENT_SELECTION,
  REFRESH_SESSION,
  HOME_WITH_THEME_LANG,
} from './routes-path';
import { getCookie, setCookie } from '../shared/helpers';
import { GeneralContext } from '../shared/contexts/GeneralContext';
import { logout } from '../shared/providers/actions/user';

function Routes() {
  useHashFragment();
  const { clearAmosToken, setUserData } = useContext(GeneralContext);
  const [theme, setTheme] = useState(basic);
  const history = useHistory();
  const { idiom } = useTranslation({});

  useEffect(() => {
    switch (localStorage.getItem('theme')) {
      case 'pitang':
        setTheme(pitang);
        break;
      default:
        setTheme(basic);
        break;
    }
  }, [localStorage.getItem('theme')]);

  useEffect(() => {
    const typeNavigation =
      performance?.getEntriesByType('navigation')?.[0]?.type;
    if (typeNavigation === 'reload') {
      setCookie('userAuth', '{}');
      setUserData({});
      clearAmosToken();

      const path = window.location.pathname + window.location.search;
      localStorage.setItem('oldPath', path);
      history.push('/login');
    }
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script={
              window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK
                ? process.env.REACT_APP_ONETRUST_COOKIE
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_INSUREFOR
                ? process.env.REACT_APP_ONETRUST_COOKIE_INSUREFOR
                : window.location.hostname == process.env.REACT_APP_DOMAIN_US
                ? process.env.REACT_APP_ONETRUST_COOKIE_US
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_US_CHUBB
                ? process.env.REACT_APP_ONETRUST_COOKIE_CHUBB
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_US_METLIFE
                ? process.env.REACT_APP_ONETRUST_COOKIE_METLIFE
                : window.location.hostname == process.env.REACT_APP_DOMAIN_USTI
                ? process.env.REACT_APP_ONETRUST_COOKIE_USTI
                : window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS
                ? process.env.REACT_APP_ONETRUST_COOKIE_WWIIS
                : process.env.REACT_APP_ONETRUST_COOKIE_GENERIC
            }
          />
          <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        </Helmet>

        <Header />
        <Switch>
          <Route
            exact
            path={LOGIN}
            render={() => {
              window.location.href = '/login';
              return <></>;
            }}
          />
          <Route
            exact
            path={`${LOGIN}?lang=${idiom.substr(0, 2)}`}
            render={() => {
              window.location.href = '/login';
              return <></>;
            }}
          />
          <Route
            exact
            path={LOGOUT}
            render={() => {
              sessionStorage.setItem('amosToken', '');
              sessionStorage.setItem('amosRefreshToken', '');
              sessionStorage.setItem('amosClient', '');
              setCookie('user', '');
              window.location.href = '/logout';
              return <></>;
            }}
          />
          <Route
            exact
            path={REFRESH_SESSION}
            render={() => {
              sessionStorage.setItem('amosToken', '');
              sessionStorage.setItem('amosRefreshToken', '');
              sessionStorage.setItem('amosClient', '');
              window.location.href = '/logout';
              return <></>;
            }}
          />
          <Route path={LOGIN_CALLBACK} exact>
            <LoginCallback />
          </Route>
          <Route path={HOME} exact>
            <Home />
          </Route>
          <Route path={HOME_WITH_THEME} exact>
            <Home />
          </Route>
          <Route path={HOME_WITH_THEME_LANG} exact>
            <Home />
          </Route>
          <Route path={LETS_START}>
            <LetsStart />
          </Route>
          <Route path={PATIENT_SELECTION}>
            <PatientSelection />
          </Route>
          <Route path={REQUEST}>
            <Request />
          </Route>
        </Switch>
        <Space />
        <Footer />
      </ThemeProvider>
    </StylesProvider>
  );
}

const Space = styled.div`
  width: 100%;
  height: 20px;
`;

export default Routes;
