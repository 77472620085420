/* eslint-disable no-undef */
import ReactGA from 'react-ga4';
const GA_ID =
  window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK
    ? process.env.REACT_APP_GOOGLE_ANALYTICS
    : window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_INSUREFOR
    : window.location.hostname == process.env.REACT_APP_DOMAIN_US
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_US
    : window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_CHUBB
    : window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_METLIFE
    : window.location.hostname == process.env.REACT_APP_DOMAIN_USTI
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_USTI
    : window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_WWIIS
    : process.env.REACT_APP_GOOGLE_ANALYTICS_GENERIC;
export const initializeGoogleAnalytics = () => {
  // Initialize google analytics page view tracking
  ReactGA.initialize(GA_ID);
};
