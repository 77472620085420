/* eslint-disable no-undef */
import React, { useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Collapse,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Person, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Button, ConfirmationModal } from '../';
import * as translations from './intl';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import api from '../../services';
import { useTranslation } from '../../shared/hooks';
import { getCookie, setCookie } from '../../shared/helpers/cookies';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 175,
    maxWidth: 275,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      //   backgroundColor: theme.palette.primary.main,
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      color: theme.palette.secondary.main,
    },
  },
}))(MenuItem);

const styles = makeStyles((theme) => ({
  buttonOpen: {
    minWidth: 175,
    maxWidth: 275,
    justifyContent: 'space-around',
  },
}));

export default function UserMenu({ mobile = false }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { locale } = useTranslation({
    translationsFile: translations,
  });
  const { userData, logoutAmos, setIsLoading } = useContext(GeneralContext);
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMobileCollapse, setOpenMobileCollapse] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    logoutAmos();
    const brand = getCookie('brand');
    const policyNumber = getCookie('policyNumber');
    if (brand && policyNumber) {
      if (brand === 'CoverForYou') {
        setCookie(
          'brandCookieURL',
          process.env.REACT_APP_WWIIS_URL1 + `${policyNumber}`
        );
      } else if (brand === 'CedarTree') {
        setCookie(
          'brandCookieURL',
          process.env.REACT_APP_WWIIS_URL2 + `${policyNumber}`
        );
      } else if (brand === 'Outbacker') {
        setCookie(
          'brandCookieURL',
          process.env.REACT_APP_WWIIS_URL3 + `${policyNumber}`
        );
      }
    }
    history.push('/logoff');
    window.dataLayer.push({
      zone: 'profile',
      element_clicked: 'logout',
      event: 'navigation',
    });
  };

  const handleChangePassword = async () => {
    try {
      setIsLoading(true);
      await api.userServices.requestNewPassword({ email: userData.email });
      setOpen(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
    window.dataLayer.push({
      zone: 'profile',
      element_clicked: 'change password',
      event: 'navigation',
    });
  };

  const { CHANGE_PASSWORD, LOGOUT, PASSWORD_RESET_TEXT } = locale;

  const closeModal = () => setOpen(false);

  const handleCollapse = () => setOpenMobileCollapse(!openMobileCollapse);

  const iconButton = useMemo(
    () => (mobile ? openMobileCollapse : anchorEl),
    [mobile, openMobileCollapse, anchorEl]
  );

  return (
    <div>
      <ConfirmationModal
        open={open}
        handleClose={closeModal}
        handleAction={closeModal}
        title={CHANGE_PASSWORD}
        text={PASSWORD_RESET_TEXT}
        okButton
      />
      {userData && (
        <>
          <Button
            text={`${userData.given_name} ${userData.family_name}`}
            variant="outlined"
            className={classes.buttonOpen}
            color="primary"
            icon={<Person />}
            endIcon={iconButton ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={mobile ? handleCollapse : handleClick}
          />
          {!mobile && (
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleChangePassword}>
                <ListItemText primary={CHANGE_PASSWORD} />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleLogout}>
                <ListItemText primary={LOGOUT} />
              </StyledMenuItem>
            </StyledMenu>
          )}
          {mobile && (
            <>
              <Collapse in={openMobileCollapse} unmountOnExit>
                <StyledMenuItem onClick={handleChangePassword}>
                  <ListItemText primary={CHANGE_PASSWORD} />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleLogout}>
                  <ListItemText primary={LOGOUT} />
                </StyledMenuItem>
              </Collapse>
            </>
          )}
        </>
      )}
    </div>
  );
}
