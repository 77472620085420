/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  SwipeableDrawer,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from '../../shared/hooks';
import {
  es_ES,
  en_US,
  pt_BR,
  fr_FR,
  de_DE,
  it_IT,
} from '../../shared/constants/idioms';
import * as translations from './intl';
import PublicIcon from '@material-ui/icons/Public';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Container, NavButton } from './styles';
import { Loading, UserMenu } from '../';
import logo from '../../assets/images/Doctor_Please_Logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DOMPurify from 'dompurify';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import {
  getCookie,
  setCookie,
  deleteCookie,
} from '../../shared/helpers/cookies';
import { language } from '../../globalConstants';
import { partner_id } from '../../globalConstants';
import { partner_name } from '../../globalConstants';
import { country_code } from '../../globalConstants';
import { instance_env } from '../../globalConstants';
const styles = makeStyles((theme) => ({
  active: {
    borderBottom: `0.25em solid ${theme.palette.warning.main} !important`,
  },
  w100: {
    width: '100%',
  },
  p0: {
    padding: '0',
  },
  languageSelect: {
    width: '7.5em',
  },
  drawerButton: {
    fontFamily: 'Source Sans Pro !important',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '1px',
    height: '72px',
    minWidth: '165px',
    color: '#00008F',
    paddingLeft: '1em',
    paddingRight: '1em',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '0.25em solid rgba(0,0,0,0)',
    '&:hover': {
      textDecoration: 'none',
      color: '#00008F',
      backgroundColor: 'rgba(0,0,0,0)',
      borderBottom: '0.25em solid #F07662',
    },
  },
  drawerButtonBack: {
    color: '#00008F',
    justifyContent: 'left',
    alignItems: 'left',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  drawerBackButton: {
    width: '20em',
    height: '18px',
  },
  menuIcon: {
    color: '#00008F',
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
  fontCustom: {
    lineHeight: '21px',
    fontStyle: 'normal',
    fontWeight: '600',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  grayColor: {
    color: '#999999',
  },
  mx1: {
    marginLeft: '1em',
    marginRight: '1em',
  },
  gridBackButton: {
    backgroundColor: '#FAFAFA',
    height: '2.5em',
  },
  gridHeaderButtons: {
    height: '4em',
  },
  iconSize: {
    width: '1em',
    height: '1em',
  },
  dividerLanguageSelection: {
    height: '2em',
    width: '2px',
    backgroundColor: '#fafafa',
    margin: '0',
    opacity: 1,
  },
  flagHeight: {
    height: '25px',
  },
  mr1: {
    marginRight: '1em',
  },
  headerShadow: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
  },
  h100: {
    height: '100%',
  },
  topMenu: {
    height: '55vh',
    minHeight: '21em',
  },
  bottomMenu: {
    height: '15vh',
    minHeight: '6em',
  },
  h4: {
    height: '4em',
  },
  w4: {
    width: '4em',
  },
  mobileDivider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#fafafa',
    margin: '0',
    opacity: 1,
  },
  my1: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  bottomTopMenu: {
    maxHeight: '50%',
    backgroundColor: '#fafafa',
  },
  bottomBottomMenu: {
    height: '48%',
  },
  w80: {
    width: '80%',
  },
  headerButton: {
    height: '64px',
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  mobileHeaderButton: {
    height: '48px',
    width: '100%',
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
  fontSize13: {
    fontSize: '13px',
  },
  chevronDown: {
    position: 'absolute',
    color: '#00008f',
    right: '0',
    pointerEvents: 'none',
  },
  fontSize14: {
    fontSize: '14px',
  },
  blackColor: {
    color: 'black',
  },
  fontBold: {
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '13px',
    letterSpacing: '0.2px',
  },
  alignItems: {
    alignItems: 'flex-start',
    fontWeight: 600,
  },
}));

export default function Header() {
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { locale, getIdiom, setIdiom } = useTranslation({
    translationsFile: translations,
  });
  const idiom = getIdiom();
  const { userData } = useContext(GeneralContext);

  const {
    LABEL_PT,
    LABEL_ES,
    LABEL_EN,
    LABEL_FR,
    LABEL_DE,
    LABEL_IT,
    BACK_TO_TRAVEL_BUTTON,
    BACK_TO_COVERFORYOU_BUTTON,
    BACK_TO_CEDARTREE_BUTTON,
    BACK_TO_OUTBACKER_BUTTON,
    REQUEST_VIDEO_CONSULTATION,
    REQUEST_PHONE_CALL,
    APPOINTMENTS,
    TELECONSULTATION,
    REQUEST_VIDEO_CONSULTATION_BR,
    REQUEST_PHONE_CALL_BR,
    APPOINTMENTS_BR,
    TELECONSULTATION_BR,
  } = locale;

  const languages = [
    { label: LABEL_ES, value: es_ES, idiom: 'es_ES' },
    { label: LABEL_EN, value: en_US, idiom: 'en_US' },
    { label: LABEL_PT, value: pt_BR, idiom: 'pt_BR' },
    { label: LABEL_FR, value: fr_FR, idiom: 'fr_FR' },
    { label: LABEL_DE, value: de_DE, idiom: 'de_DE' },
    { label: LABEL_IT, value: it_IT, idiom: 'it_IT' },
  ];

  const handleChangeIdiom = async (e) => {
    try {
      setIsLoading(true);
      setIdiom(e.target.value);
      setIsLoading(false);
      document.documentElement.lang = e.target.value.substr(0, 2);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const location = useLocation();
  const noHash = !['#appointments'].includes(location.hash);

  const isRequestVideoActive = () => {
    const firstVerify = ['/patient-selection?type=video'].includes(
      location.pathname + location.search
    );

    const secondVerify = ['/lets-start?type=video'].includes(
      location.pathname + location.search
    );
    if (secondVerify) return secondVerify && noHash;

    if (!firstVerify) {
      const arr = location.pathname.split('/');
      return arr.includes('request') && arr.includes('video');
    }

    return firstVerify && noHash;
  };

  const isRequestPhoneActive = () => {
    const firstVerify = ['/patient-selection?type=phone'].includes(
      location.pathname + location.search
    );

    const secondVerify = ['/lets-start?type=phone'].includes(
      location.pathname + location.search
    );
    if (secondVerify) return secondVerify && noHash;

    if (!firstVerify) {
      const arr = location.pathname.split('/');
      return arr.includes('request') && arr.includes('phone');
    }

    return firstVerify && noHash;
  };

  const isAppointmentsActive = () => {
    return ['#appointments'].includes(location.hash);
  };

  const isHomeActive = () => {
    return (
      ['/', '/login-callback'].includes(location.pathname) &&
      !['#appointments'].includes(location.hash)
    );
  };

  const getFlag = () => {
    return (
      <PublicIcon className={`${classes.grayColor} ${classes.iconSize}`} />
    );
  };
  let sourceCS = false;
  let sourceMTC = false;
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (
    window.location.hostname == process.env.REACT_APP_DOMAIN_GENERIC ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_US ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_USTI ||
    window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS
  ) {
    if (params.source && params.source.toLowerCase() === 'cs') {
      sourceCS = true;
      setCookie('source', 'CS');
    } else if (params.source && params.source.toLowerCase() != 'cs') {
      sourceCS = false;
      deleteCookie('source');
    } else if (
      getCookie('source') &&
      getCookie('source').toLowerCase() == 'cs'
    ) {
      sourceCS = true;
    } else if (params.utm_source && params.utm_source.toLowerCase() === 'mtc') {
      sourceMTC = true;
      setCookie('source', 'MTC');
    } else if (params.utm_source && params.utm_source.toLowerCase() != 'mtc') {
      sourceMTC = false;
      deleteCookie('source');
    } else if (
      getCookie('source') &&
      getCookie('source').toLowerCase() == 'mtc'
    ) {
      sourceMTC = true;
    }
  }

  return (
    <Container data-testid="header">
      <Grid container className={classes.w100}>
        <Loading active={isLoading} />
        <Hidden only={['xl', 'lg', 'md']}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={`${classes.gridBackButton} ${classes.mx1}`}>
              {window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_INSUREFOR
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname == process.env.REACT_APP_DOMAIN_US
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_US_CHUBB
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname ==
                  process.env.REACT_APP_DOMAIN_US_METLIFE
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname == process.env.REACT_APP_DOMAIN_USTI
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS
                ? BackToTravelButton(
                    classes,
                    '',
                    BACK_TO_COVERFORYOU_BUTTON,
                    BACK_TO_CEDARTREE_BUTTON,
                    BACK_TO_OUTBACKER_BUTTON
                  )
                : sourceCS
                ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                : ''}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden only={['sm', 'xs']}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item className={`${classes.gridBackButton} ${classes.mx1}`}>
                {window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname ==
                    process.env.REACT_APP_DOMAIN_INSUREFOR
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname == process.env.REACT_APP_DOMAIN_US
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname ==
                    process.env.REACT_APP_DOMAIN_US_CHUBB
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname ==
                    process.env.REACT_APP_DOMAIN_US_METLIFE
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname ==
                    process.env.REACT_APP_DOMAIN_USTI
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : window.location.hostname ==
                    process.env.REACT_APP_DOMAIN_WWIIS
                  ? BackToTravelButton(
                      classes,
                      '',
                      BACK_TO_COVERFORYOU_BUTTON,
                      BACK_TO_CEDARTREE_BUTTON,
                      BACK_TO_OUTBACKER_BUTTON
                    )
                  : sourceCS
                  ? BackToTravelButton(classes, BACK_TO_TRAVEL_BUTTON)
                  : ''}
              </Grid>
              <Grid
                item
                alignItems="center"
                justifyContent="center"
                className={`${classes.mx1} d-flex ${classes.alignItems}`}
              >
                <Divider
                  orientation="vertical"
                  variant="middle"
                  className={`${classes.dividerLanguageSelection}`}
                  flexItem
                />
                <Grid
                  item
                  alignItems="center"
                  className={`${classes.mx1} ${classes.flagHeight}`}
                  justifyContent="center"
                >
                  {getFlag()}
                </Grid>
                <Grid
                  item
                  className={`${classes.languageSelect} ${classes.mr1}`}
                >
                  {SelectLanguage(idiom, handleChangeIdiom, languages, classes)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={`${classes.headerShadow}`}
          >
            <Grid item md={1}>
              {PageIcon(classes)}
            </Grid>
            <Grid
              item
              md={11}
              justifyContent="flex-end"
              alignItems="center"
              className={`d-flex ${classes.gridHeaderButtons}`}
            >
              {userData?.email_verified && (
                <Grid item>
                  {TeleconsultationButton(
                    isHomeActive,
                    classes,
                    TELECONSULTATION_BR
                  )}
                  {RequestVideoButton(
                    idiom,
                    isRequestVideoActive,
                    classes,
                    REQUEST_VIDEO_CONSULTATION_BR
                  )}
                  {RequestPhoneButton(
                    idiom,
                    isRequestPhoneActive,
                    classes,
                    REQUEST_PHONE_CALL_BR
                  )}
                  {AppointmentsButton(
                    isAppointmentsActive,
                    classes,
                    APPOINTMENTS_BR
                  )}
                </Grid>
              )}
              {userData?.email_verified && (
                <Grid item className={classes.mx1}>
                  <UserMenu />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.headerShadow}
          >
            <Grid item>{PageIcon(classes)}</Grid>
            <Grid item>
              <IconButton onClick={handleOpenDrawer}>
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden only={['xl', 'lg', 'md']}>
        <SwipeableDrawer
          anchor="right"
          open={openDrawer}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
        >
          <Grid container className={`${classes.w100} ${classes.h100}`}>
            <Grid container className={`${classes.w100} ${classes.h100}`}>
              <Grid className={`${classes.w100} ${classes.topMenu}`}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={`${classes.w100} ${classes.h4}`}
                >
                  <Grid item alignItems="center">
                    {PageIcon(classes)}
                  </Grid>
                  <Grid item className={`${classes.h100} ${classes.w4}`}>
                    <Grid
                      container
                      justifyContent="center"
                      className={`${classes.h100} ${classes.w4}`}
                    >
                      <Grid item alignItems="center">
                        <IconButton
                          onClick={handleCloseDrawer}
                          className={classes.h100}
                        >
                          <CloseRoundedIcon className={classes.menuIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider className={classes.mobileDivider} flexItem />
                {userData?.email_verified && (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={`${classes.my1}`}
                    >
                      <Grid item>
                        <UserMenu mobile />
                      </Grid>
                    </Grid>
                    <Divider className={classes.mobileDivider} flexItem />
                    <Grid className={classes.w100}>
                      <Grid item>
                        {TeleconsultationButton(
                          isHomeActive,
                          classes,
                          TELECONSULTATION,
                          true,
                          handleCloseDrawer
                        )}
                      </Grid>
                      <Divider className={classes.mobileDivider} flexItem />
                      <Grid item>
                        {RequestVideoButton(
                          idiom,
                          isRequestVideoActive,
                          classes,
                          REQUEST_VIDEO_CONSULTATION,
                          true,
                          handleCloseDrawer
                        )}
                      </Grid>
                      <Divider className={classes.mobileDivider} flexItem />
                      <Grid item>
                        {RequestPhoneButton(
                          idiom,
                          isRequestPhoneActive,
                          classes,
                          REQUEST_PHONE_CALL,
                          true,
                          handleCloseDrawer
                        )}
                      </Grid>
                      <Divider className={classes.mobileDivider} flexItem />
                      <Grid>
                        {AppointmentsButton(
                          isAppointmentsActive,
                          classes,
                          APPOINTMENTS,
                          true,
                          handleCloseDrawer
                        )}
                      </Grid>
                      <Divider className={classes.mobileDivider} flexItem />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid className={`${classes.w100} ${classes.bottomMenu}`}>
                <Divider className={classes.mobileDivider} flexItem />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={`${classes.bottomBottomMenu} ${classes.w100}`}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={classes.w100}
                  >
                    <Grid item className={classes.mr1}>
                      {getFlag()}
                    </Grid>
                    <Grid
                      item
                      className={`${classes.languageSelect} ${classes.w80}`}
                    >
                      {SelectLanguage(
                        idiom,
                        handleChangeIdiom,
                        languages,
                        classes
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Hidden>
    </Container>
  );
}

function PageIcon(classes) {
  return (
    <a href="/">
      <img src={logo} height="57px" alt="" className={classes.mx1} />
    </a>
  );
}

function AppointmentsButton(
  isAppointmentsActive,
  classes,
  APPOINTMENTS,
  mobile = false,
  handleCloseDrawer = () => {}
) {
  const history = useHistory();
  return (
    <NavButton
      className={`${isAppointmentsActive() ? classes.active : ''} ${
        classes.drawerButton
      } ${classes.sourceSans} ${classes.upperCase} ${
        mobile ? classes.mobileHeaderButton : classes.headerButton
      }`}
      onClick={() => {
        handleCloseDrawer();
        history.push('/#appointments');

        window.dataLayer.push({
          zone: 'header',
          element_clicked: 'appointments',
          event: 'navigation',
        });

        window.dataLayer.push({
          subsidiary_name: 'axa_partners',
          business_unit: 'customer_and_solutions',
          business_line: 'travel_solutions',
          digital_business_process: 'assistance_request',
          global_digital_asset: 'doctor_please',
          partner_id: partner_id,
          partner_name: partner_name,
          country: country_code,
          language: language,
          instance_environment: instance_env,
          user_logged: 'true',
          page_name: 'appointments list',
          appointment_type: 'video call',
          funnel_step: '3',
          step_name: 'appointments list',
          event: 'pageview',
        });
      }}
    >
      <span
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(APPOINTMENTS) }}
      ></span>
    </NavButton>
  );
}

function RequestPhoneButton(
  idiom,
  isRequestPhoneActive,
  classes,
  REQUEST_PHONE_CALL,
  mobile = false,
  handleCloseDrawer = () => {}
) {
  const history = useHistory();
  const { userData, isLogged } = useContext(GeneralContext);
  const handleButton = async (path) => {
    if (isLogged && userData?.isRegisteredAmos) {
      history.push(`/patient-selection?type=${path}`);

      window.dataLayer.push({
        zone: 'header',
        element_clicked: 'phone consultation',
        service_category: 'health',
        event: 'start_journey',
      });

      window.dataLayer.push({
        subsidiary_name: 'axa_partners',
        business_unit: 'customer_and_solutions',
        business_line: 'travel_solutions',
        digital_business_process: 'assistance_request',
        global_digital_asset: 'doctor_please',
        partner_id: partner_id,
        partner_name: partner_name,
        country: country_code,
        language: language,
        instance_environment: instance_env,
        user_logged: 'true',
        page_name: 'patient selection',
        funnel_step: '1',
        step_name: 'request appointment',
        event: 'pageview',
      });
    }

    if (isLogged && userData?.isRegisteredAmos === false) {
      history.push(`/lets-start?type=${path}`);

      window.dataLayer.push({
        zone: 'header',
        element_clicked: 'call back request',
        service_category: 'health',
        event: 'start_journey',
      });

      window.dataLayer.push({
        subsidiary_name: 'axa_partners',
        business_unit: 'customer_and_solutions',
        business_line: 'travel_solutions',
        digital_business_process: 'assistance_request',
        global_digital_asset: 'doctor_please',
        partner_id: partner_id,
        partner_name: partner_name,
        country: country_code,
        language: language,
        instance_environment: instance_env,
        user_logged: 'true',
        page_name: 'register doctor please',
        funnel_step: '0.1',
        step_name: 'register doctor please',
        event: 'pageview',
      });
    }

    if (!isLogged) {
      history.push(`/login?lang=${idiom.substr(0, 2)}`);
    }
  };

  return (
    <NavButton
      className={`${isRequestPhoneActive() ? classes.active : ''} ${
        classes.drawerButton
      } ${classes.sourceSans} ${classes.upperCase} ${
        mobile ? classes.mobileHeaderButton : classes.headerButton
      }`}
      onClick={() => {
        handleButton('phone');
        handleCloseDrawer();
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(REQUEST_PHONE_CALL),
        }}
      ></span>
    </NavButton>
  );
}

function RequestVideoButton(
  idiom,
  isRequestVideoActive,
  classes,
  REQUEST_VIDEO_CONSULTATION,
  mobile = false,
  handleCloseDrawer = () => {}
) {
  const history = useHistory();
  const { userData, isLogged } = useContext(GeneralContext);
  const handleButton = async (path) => {
    if (isLogged && userData?.isRegisteredAmos) {
      history.push(`/patient-selection?type=${path}`);

      window.dataLayer.push({
        zone: 'header',
        element_clicked: 'video consultation',
        service_category: 'health',
        event: 'start_journey',
      });

      window.dataLayer.push({
        subsidiary_name: 'axa_partners',
        business_unit: 'customer_and_solutions',
        business_line: 'travel_solutions',
        digital_business_process: 'assistance_request',
        global_digital_asset: 'doctor_please',
        partner_id: partner_id,
        partner_name: partner_name,
        country: country_code,
        language: language,
        instance_environment: instance_env,
        user_logged: 'true',
        page_name: 'patient selection',
        funnel_step: '1',
        step_name: 'request appointment',
        event: 'pageview',
      });
    }

    if (isLogged && userData?.isRegisteredAmos === false) {
      history.push(`/lets-start?type=${path}`);

      window.dataLayer.push({
        zone: 'header',
        element_clicked: 'video consultation',
        service_category: 'health',
        event: 'start_journey',
      });

      window.dataLayer.push({
        subsidiary_name: 'axa_partners',
        business_unit: 'customer_and_solutions',
        business_line: 'travel_solutions',
        digital_business_process: 'assistance_request',
        global_digital_asset: 'doctor_please',
        partner_id: partner_id,
        partner_name: partner_name,
        country: country_code,
        language: language,
        instance_environment: instance_env,
        user_logged: 'true',
        page_name: 'register doctor please',
        funnel_step: '0.1',
        step_name: 'register doctor please',
        event: 'pageview',
      });
    }

    if (!isLogged) {
      history.push(`/login?lang=${idiom.substr(0, 2)}`);
    }
  };
  return (
    <NavButton
      className={`${isRequestVideoActive() ? classes.active : ''} ${
        classes.drawerButton
      } ${classes.sourceSans} ${classes.upperCase} ${
        mobile ? classes.mobileHeaderButton : classes.headerButton
      }`}
      onClick={() => {
        handleButton('video');
        handleCloseDrawer();
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(REQUEST_VIDEO_CONSULTATION),
        }}
      ></span>
    </NavButton>
  );
}

function TeleconsultationButton(
  isHomeActive,
  classes,
  TELECONSULTATION,
  mobile = false,
  handleCloseDrawer = () => {}
) {
  const history = useHistory();
  return (
    <NavButton
      className={`${isHomeActive() ? classes.active : ''} ${
        classes.drawerButton
      } ${classes.sourceSans} ${classes.upperCase} ${
        mobile ? classes.mobileHeaderButton : classes.headerButton
      }`}
      onClick={() => {
        handleCloseDrawer();
        history.push('/');

        window.dataLayer.push({
          zone: 'header',
          element_clicked: 'home',
          event: 'navigation',
        });

        window.dataLayer.push({
          subsidiary_name: 'axa_partners',
          business_unit: 'customer_and_solutions',
          business_line: 'travel_solutions',
          digital_business_process: 'assistance_request',
          global_digital_asset: 'doctor_please',
          partner_id: partner_id,
          partner_name: partner_name,
          country: country_code,
          language: language,
          instance_environment: instance_env,
          user_logged: 'true',
          page_name: 'homepage',
          event: 'pageview',
        });
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(TELECONSULTATION),
        }}
      ></span>
    </NavButton>
  );
}

function SelectLanguage(idiom, handleChangeIdiom, languages, classes) {
  return (
    <FormControl fullWidth>
      <Select
        value={idiom}
        onChange={handleChangeIdiom}
        disableUnderline
        data-testid="languageSelect"
        className={`${classes.sourceSans} ${classes.upperCase} ${classes.grayColor} ${classes.fontBold} ${classes.menuIcon} ${classes.fontSize13}`}
        IconComponent={() => <ExpandMoreIcon className={classes.chevronDown} />}
      >
        {languages?.map((el, key) => (
          <MenuItem value={el.value} key={key}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function BackToTravelButton(
  classes,
  BACK_TO_TRAVEL_BUTTON,
  BACK_TO_COVERFORYOU_BUTTON,
  BACK_TO_CEDARTREE_BUTTON,
  BACK_TO_OUTBACKER_BUTTON,
  blackText = false,
  handleCloseDrawer = () => {}
) {
  const idiom = getCookie('idiom');
  let URL = '';
  const urlParams = new URLSearchParams(window.location.search);

  // Get query parameters
  const brand = urlParams.get('Brand');
  const policyNumber = urlParams.get('policyNumber');
  if (brand && policyNumber) {
    setCookie('policyNumber', policyNumber);
    setCookie('brand', brand);
  }
  if (window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK) {
    URL = process.env.REACT_APP_TRIP_UK_URL;
  } else if (window.location.hostname == process.env.REACT_APP_DOMAIN_GENERIC) {
    URL = process.env.REACT_APP_MYTRIP_COMPANION_GENERIC;
  } else if (
    window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR
  ) {
    URL = process.env.REACT_APP_INSUREFOR_UK_URL;
  } else if (window.location.hostname == process.env.REACT_APP_DOMAIN_US) {
    URL = process.env.REACT_APP_US_URL;
  } else if (
    window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB
  ) {
    URL = process.env.REACT_APP_US_CHUBB_URL;
  } else if (
    window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE
  ) {
    URL = process.env.REACT_APP_US_METLIFE_URL;
  } else if (window.location.hostname == process.env.REACT_APP_DOMAIN_USTI) {
    URL = process.env.REACT_APP_USTI_URL;
  } else if (window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS) {
    const brandCookies = getCookie('brand');
    const policyNumbers = getCookie('policyNumber');
    if (!brand && !policyNumber && !brandCookies && !policyNumbers) {
      const arrow = document.querySelector('[name="backArrow"]');
      if (arrow) {
        arrow.style.display = 'none';
      }
    }
    if (brandCookies == 'CoverForYou') {
      URL = process.env.REACT_APP_WWIIS_URL1 + `${policyNumbers}`;
    } else if (brandCookies == 'CedarTree') {
      URL = process.env.REACT_APP_WWIIS_URL2 + `${policyNumbers}`;
    } else if (brandCookies == 'Outbacker') {
      URL = process.env.REACT_APP_WWIIS_URL3 + `${policyNumbers}`;
    }
  }
  const brandCookie = getCookie('brand');
  if (
    idiom != undefined &&
    idiom != null &&
    URL != '' &&
    window.location.hostname != process.env.REACT_APP_DOMAIN_WWIIS
  ) {
    URL += '?lng_code=' + idiom.substr(0, 2);
  }
  return (
    <Button
      className={`${classes.drawerButtonBack} ${classes.sourceSans} ${
        classes.upperCase
      } ${classes.fontSize14} ${
        blackText ? classes.blackColor : classes.menuIcon
      } ${classes.fontCustom}`}
      variant="text"
      name="backArrow"
      startIcon={<ArrowBackIcon />}
      href={URL}
      onClick={() => {
        window.dataLayer.push({
          zone: 'header',
          element_clicked: 'home',
          event: 'navigation',
        });
        handleCloseDrawer();
      }}
    >
      {process.env.REACT_APP_DOMAIN_WWIIS && brandCookie == 'CoverForYou'
        ? BACK_TO_COVERFORYOU_BUTTON
        : process.env.REACT_APP_DOMAIN_WWIIS && brandCookie == 'CedarTree'
        ? BACK_TO_CEDARTREE_BUTTON
        : process.env.REACT_APP_DOMAIN_WWIIS && brandCookie == 'Outbacker'
        ? BACK_TO_OUTBACKER_BUTTON
        : BACK_TO_TRAVEL_BUTTON}
    </Button>
  );
}
