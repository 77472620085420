import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  InputText,
  InputMask,
  AutoComplete,
  DarkTitle,
  CheckBox,
  Button,
  TermsAndConditions,
  PrivacyPolicy,
  ConfirmationModal,
  DatePicker,
} from '../../components';
import { useTranslation, useQuery } from '../../shared/hooks';
import * as translations from './intl';
import { Formik, Form } from 'formik';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import {
  maskedCellphone,
  maskedAccessCode,
} from '../../shared/constants/masks';
import accessCodeValidation from '../../shared/constants/accesscode';
import api from '../../services';
import * as Yup from 'yup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { language } from '../../globalConstants';
import { partner_id } from '../../globalConstants';
import { partner_name } from '../../globalConstants';
import { country_code } from '../../globalConstants';
import { instance_env } from '../../globalConstants';

const styles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Publico Headline !important',
    fontSize: '36px !important',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important',
    },
    lineHeight: '40px !important',
    color: theme.palette.text.main,
    paddingBottom: 42,
    paddingTop: 42,
  },
  gridSubTitle: {
    borderBottom: '1px solid #ccc',
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 30,
    },
  },
  subTitle: {
    fontFamily: 'Publico Headline',
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '0.8px',
    padding: '10px 50px',
    color: theme.palette.text.main,
  },
  containerInput: {
    paddingTop: '44px !important',
    [theme.breakpoints.down('md')]: {
      padding: '0px 40px !important',
    },
    padding: '0px 90px !important',
    marginBottom: 20,
  },
  paperContent: {
    width: '100%',
  },
  container: {
    marginBottom: 96,
  },
  linkCheckbox: {
    fontWeight: 700,
    fontFamily: 'Source Sans Pro !important',
    color: theme.palette.primary.main,
  },
  textCheckbox: {
    color: theme.palette.text.main,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Source Sans Pro !important',
    marginBottom: 0,
  },
  paddingFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '25px 29px',
    borderTop: '1px solid rgba(229, 229, 229, 1)',
  },
  gridCheckbox: {
    padding: '0 10px',
  },
  w100: {
    width: '100%',
  },
  chevronDown: {
    position: 'absolute',
    color: '#00008f',
    right: '0',
    pointerEvents: 'auto',
    fontSize: '16px',
    cursor: 'pointer',
  },
  inputDescription: {
    visibility: 'Visible',
    fontSize: '15px',
    fontWeight: 'normal',
    fontFamily: 'Source Sans Pro !important',
  },
  inputDescriptionHide: {
    position: 'absolute',
    visibility: 'Hidden',
    fontSize: '15px',
    fontWeight: 'normal',
    fontFamily: 'Source Sans Pro !important',
  },
  textDescriptionField: {
    marginBottom: 0,
  },
}));

function LetsStart() {
  const history = useHistory();
  const query = useQuery();
  const type = query.get('type');
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showEmailMsg, setShowEmailMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let emailRef = useRef(null);

  const handleAccessCodeClick = () => {
    setIsOpen(!isOpen);
  };

  const handleShowTerms = () => {
    setShowTerms((showTerms) => !showTerms);
  };

  const handleShowPrivacy = () => {
    setShowPrivacy((showPrivacy) => !showPrivacy);
  };
  const {
    clientCode,
    setIsLoading,
    country,
    countryDdi,
    fetchAmosCountry,
    loginAmos,
    userData,
  } = useContext(GeneralContext);
  const { locale, idiomAmos } = useTranslation({
    translationsFile: translations,
  });

  const {
    LABEL_ES,
    LABEL_EN,
    LABEL_PT,
    LABEL_FR,
    LABEL_DE,
    LABEL_IT,
    TITLE,
    FORM_TITLE,
    VALIDATION_REQUIRED,
    FIRSTNAME_FIELD,
    LASTNAME_FIELD,
    EMAILADDRESS_FIELD,
    DATEOFBIRTH_FIELD,
    GENDER_FIELD,
    MALE_LABEL,
    FEMALE_LABEL,
    LANGUAGE_FIELD,
    COUNTRY_RESIDENCE_FIELD,
    COUNTRY_CODE_FIELD,
    TELEPHONE_NUMBER_FIELD,
    ACCESS_CODE_FIELD,
    TERMS_AND_CONDITION_0,
    TERMS_AND_CONDITION_1,
    PRIVACY_NOTICE_0,
    PRIVACY_NOTICE_1,
    REGISTER_BUTTON,
    VALIDATION_MUST_BE_THAN_18,
    VALIDATE_ACCESS_CODE,
    EMAIL_ALREADY_EXISTS_TITLE_MESSAGE,
    EMAIL_ALREADY_EXISTS,
    REGISTRATION_CONFIRMATION_TITLE,
    REGISTRATION_CONFIRMATION_MESSAGE,
    VALIDATION_VALID_DATE,
    SELECT_A_COUNTRY,
    WHERE_TO_FIND_ACCESS_CODE,
    WHERE_TO_FIND_ACCESS_CODE_DESCRIPTION,
  } = locale;

  const classes = styles();

  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData?.email));
  }, [userData]);

  let userEmail = localStorage.getItem('userData');
  if (userEmail != null) {
    userEmail = userEmail.replace(/"/g, '');
  }

  useEffect(() => {
    fetchAmosCountry(idiomAmos);
  }, [idiomAmos]);

  const initialValues = useMemo(
    () => ({
      clientCode: clientCode,
      firstName: '',
      lastName: '',
      day: '',
      month: '',
      year: '',
      gender: '',
      birthDate: null,
      email: userEmail ? userEmail : userData?.email,
      countryCode: '',
      cellphone: '',
      countryResidence: '',
      language: '',
      accessCode: '',
      terms: false,
      privacy: false,
    }),
    [userData]
  );

  const clCode = initialValues.clientCode;
  const emailInput = document.querySelector('[name="email"]');
  if (emailInput) {
    emailInput.style.color = 'rgba(0, 0, 0, 1.38)';
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(VALIDATION_REQUIRED),
    lastName: Yup.string().required(VALIDATION_REQUIRED),
    birthDate: Yup.date()
      .required(VALIDATION_REQUIRED)
      .min(new Date(1900, 0, 0), VALIDATION_VALID_DATE)
      .max(new Date(), VALIDATION_VALID_DATE)
      .test('age-validation', VALIDATION_MUST_BE_THAN_18, (item) => {
        const year = new Date().getFullYear();
        const age = parseInt(year) - parseInt(moment(item).format('yyyy'));
        return age >= 19;
      })
      .typeError(VALIDATION_VALID_DATE),
    gender: Yup.string().required(VALIDATION_REQUIRED),
    countryResidence: Yup.string().required(VALIDATION_REQUIRED),
    language: Yup.string().required(VALIDATION_REQUIRED),
    email: Yup.string().email().required(VALIDATION_REQUIRED),
    countryCode: Yup.string().required(VALIDATION_REQUIRED),
    cellphone: Yup.string().required(VALIDATION_REQUIRED),
    accessCode: Yup.string()
      .required(VALIDATION_REQUIRED)
      .test(
        'access-code-validation',
        VALIDATE_ACCESS_CODE +
          'teleconsultation-support@axa-travel-insurance.com',
        (item) => {
          return accessCodeValidation(item);
        }
      ),
    terms: Yup.boolean().oneOf([true], VALIDATION_REQUIRED),
    privacy: Yup.boolean().oneOf([true], VALIDATION_REQUIRED),
  });

  const goToPatientSelection = () => {
    setShowConfirmation(false);
    history.push(`/patient-selection?type=${type}`);

    window.dataLayer.push({
      zone: 'popup - account activated',
      element_clicked: 'ok',
      event: 'navigation',
    });

    window.dataLayer.push({
      subsidiary_name: 'axa_partners',
      business_unit: 'customer_and_solutions',
      business_line: 'travel_solutions',
      digital_business_process: 'assistance_request',
      global_digital_asset: 'doctor_please',
      partner_id: partner_id,
      partner_name: partner_name,
      country: country_code,
      language: language,
      instance_environment: instance_env,
      user_logged: 'true',
      page_name: 'patient selection',
      funnel_step: '1',
      step_name: 'request appointment',
      event: 'pageview',
    });
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const day = moment(values.birthDate).format('DD');
      const month = moment(values.birthDate).format('MM');
      const year = moment(values.birthDate).format('yyyy');
      const body = {
        client_code: clCode,
        name: values?.firstName,
        surname: values?.lastName,
        day: day,
        month: month,
        year: year,
        gender: values?.gender,
        country: values?.countryResidence,
        language: values?.language,
        email: values?.email,
        phone_prefix: values?.countryCode,
        phone: values?.cellphone,
        accessCode: values?.accessCode,
      };
      await api.amosServices.amosCreate(body);
      await loginAmos();
      setShowConfirmation(true);
      setIsLoading(false);
      window.scrollTo(0, 0);

      window.dataLayer.push({
        form_name: 'register doctor please',
        event: 'form_success',
      });

      window.dataLayer.push({
        'element_displayed ': 'popup',
        element_name: 'account activated',
        event: 'display',
      });
    } catch (err) {
      const { message } = err.response.data;

      if (message.indexOf('430') >= 0) {
        setIsLoading(false);
        setShowEmailMsg(true);
      }
    }
  };

  const closeEmailMsg = () => {
    setShowEmailMsg(false);
  };

  // eslint-disable-next-line no-undef
  if (window.location.hostname == process.env.REACT_APP_DOMAIN_AMEX) {
    let accessCodeInput = document.querySelector('.accessCodeInput');
    if (accessCodeInput) {
      accessCodeInput.disabled = 'true';
      accessCodeInput.style.color = 'rgba(0, 0, 0, 1.38)';
    }

    let accessCodeHelper = document.querySelector('#accessCodeHelper');
    if (accessCodeHelper) {
      accessCodeHelper.style.display = 'none';
    }
  }

  return (
    <>
      <TermsAndConditions
        showTerms={showTerms}
        handleShowTerms={handleShowTerms}
      />
      <PrivacyPolicy
        showPrivacy={showPrivacy}
        handleShowPrivacy={handleShowPrivacy}
      />
      <ConfirmationModal
        open={showEmailMsg}
        title={EMAIL_ALREADY_EXISTS_TITLE_MESSAGE}
        text={EMAIL_ALREADY_EXISTS}
        handleAction={closeEmailMsg}
        okButton
      />
      <ConfirmationModal
        open={showConfirmation}
        title={REGISTRATION_CONFIRMATION_TITLE}
        text={REGISTRATION_CONFIRMATION_MESSAGE}
        handleAction={goToPatientSelection}
        okButton
      />
      <Container className={classes.container}>
        <Grid item md={12}>
          <p className={classes.title}>{TITLE}</p>
        </Grid>
        <Grid item md={12} justifyContent="center" className="d-flex">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Paper className={classes.paperContent}>
                  <Grid className={classes.gridSubTitle}>
                    <p className={classes.subTitle}>{FORM_TITLE}</p>
                  </Grid>
                  <Container className={classes.containerInput}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputText name="firstName" label={FIRSTNAME_FIELD} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputText name="lastName" label={LASTNAME_FIELD} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <DatePicker
                          name="birthDate"
                          label={DATEOFBIRTH_FIELD}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoComplete
                          name="gender"
                          label={GENDER_FIELD}
                          showPlaceholder
                          options={[
                            {
                              label: MALE_LABEL,
                              value: 'male',
                            },
                            {
                              label: FEMALE_LABEL,
                              value: 'female',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputText
                          name="email"
                          label={EMAILADDRESS_FIELD}
                          inputRef={emailRef}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid item xs={12} md={12}>
                          <DarkTitle label={TELEPHONE_NUMBER_FIELD} />
                        </Grid>
                        <Grid
                          container
                          className="d-flex align-items-center"
                          spacing={2}
                        >
                          <Grid item xs={3} md={3}>
                            <AutoComplete
                              name="countryCode"
                              showPlaceholder
                              placeholder="+00"
                              options={countryDdi}
                            />
                          </Grid>
                          <Grid item xs={9} md={9}>
                            <InputMask
                              name="cellphone"
                              mask={maskedCellphone}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <p className={classes.subText}>
                              {COUNTRY_CODE_FIELD}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoComplete
                          name="countryResidence"
                          showPlaceholder
                          placeholder={SELECT_A_COUNTRY}
                          label={COUNTRY_RESIDENCE_FIELD}
                          options={country}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoComplete
                          name="language"
                          label={LANGUAGE_FIELD}
                          showPlaceholder
                          options={[
                            { label: LABEL_ES, value: 'es' },
                            { label: LABEL_EN, value: 'en' },
                            { label: LABEL_PT, value: 'pt' },
                            { label: LABEL_FR, value: 'fr' },
                            { label: LABEL_DE, value: 'de' },
                            { label: LABEL_IT, value: 'it' },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputMask
                          name="accessCode"
                          label={
                            <p className={classes.textDescriptionField}>
                              {ACCESS_CODE_FIELD}
                              <a
                                onClick={handleAccessCodeClick}
                                className={classes.chevronDown}
                                id="accessCodeHelper"
                              >
                                {WHERE_TO_FIND_ACCESS_CODE}
                                {isOpen ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </a>
                              <div
                                className={
                                  isOpen
                                    ? classes.inputDescription
                                    : classes.inputDescriptionHide
                                }
                              >
                                {WHERE_TO_FIND_ACCESS_CODE_DESCRIPTION}
                              </div>
                            </p>
                          }
                          className="accessCodeInput"
                          mask={maskedAccessCode}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.gridCheckbox}
                      >
                        <CheckBox
                          color="primary"
                          name="terms"
                          label={
                            <p className={classes.textCheckbox}>
                              {TERMS_AND_CONDITION_0}
                              <a
                                onClick={handleShowTerms}
                                className={classes.linkCheckbox}
                              >
                                {TERMS_AND_CONDITION_1}
                              </a>
                            </p>
                          }
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.gridCheckbox}
                      >
                        <CheckBox
                          color="primary"
                          name="privacy"
                          label={
                            <p className={classes.textCheckbox}>
                              {PRIVACY_NOTICE_0}
                              <a
                                onClick={handleShowPrivacy}
                                className={classes.linkCheckbox}
                              >
                                {PRIVACY_NOTICE_1}
                              </a>
                            </p>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Container>
                  <Grid container className={classes.paddingFooter}>
                    <Button
                      type="submit"
                      text={REGISTER_BUTTON}
                      variant="contained"
                      color="primary"
                    />
                  </Grid>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
      </Container>
    </>
  );
}

export default LetsStart;
